import React from "react";
import Layout from "../components/layout";
import {Jumbotron} from 'reactstrap';

const Privacy = () => {

    return <Layout>
        <Jumbotron>
            <h1>Polityka prywatności</h1>
            <h2> Właścicielem serwisu jest WBCOM SP. Z O.O..</h2>
            <p> 1. Czytając, przeglądając czy używając stronę www.wojciechbaryga.pl akceptujesz zasady zawarte w
                Polityce Prywatności.</p>
            <p> 2. Jako właściciel strony www.wojciechbaryga.pl zastrzegam sobie prawo do wprowadzania zmian w Polityce
                Prywatności.</p>
            <p> 3. Każdą osobę wykorzystującą w jakikolwiek sposób stronę w/w stronę lub kurs z www.wojciechbaryga.pl
                obowiązuje aktualna Polityka Prywatności.</p>
            <p>4. Jakiekolwiek wprowadzane zmiany nie wpływają na podstawową zasadę: nie sprzedaję i nie udostępniam
                osobom trzecim danych personalnych czy adresowych użytkowników tej strony. Dbam o Twoją prywatność.
                Jeśli nie zgadzasz się z Polityka Prywatności, proszę nie odwiedzać strony www.wojciechbaryga.pl i nie
                zapisywać się na kurs online lub też newslettery.</p>
            <p> 5. W czasie korzystania z w/w strony możesz zostać poproszony o podanie niektórych swoich danych
                osobowych poprzez wypełnienie formularza, lub w inny sposób. Dane, o które będziesz proszony to w
                większości przypadków imię i adres e-mail. W przypadku formularzy zamówień będziesz proszony o podanie
                pełnych danych osobowych. Niepodanie wymaganych danych zablokuje czynność, którą te dane dotyczyły.</p>
            <p> 6. Zapisanie się na kursy www.wojciechbaryga.pl wymaga podania w odpowiednim formularzu swojego imienia
                i poprawnego adresu e-mail. Oba te pola są obowiązkowe. Uzyskane w ten sposób dane są dodawane do listy
                mailingowej kursu online. Imię pozwala mi zwracać się do czytelników po imieniu.</p>
            <p> 7. Niezapowiedziane Wiadomości Zastrzegam sobie prawo do wysyłania niezapowiedzianych wiadomości osobom,
                których dane kontaktowe uzyskam dzięki działalności związanej z kursem online. Pod tym określeniem
                rozumiem informacje odnoszące się bezpośrednio tematyki rozwoju osobistego, zarabiania pieniędzy,
                osiąganie szczęścia, sukcesu, niekomercyjne listy (np. życzenia, informacje o wirusach, komentarze
                osobiste itp.) oraz informacje komercyjne, których rozsyłka została opłacona przez sponsora
                wspierającego kurs e-biznesu.</p>
            <p> 8. Niektóre obszary www.wojciechbaryga.pl mogą wykorzystywać cookies, czyli małe pliki tekstowe wysyłane
                do komputera internauty identyfikujące go w sposób potrzebny do uproszczenia lub umorzenia danej
                operacji.</p>
            <p> 9. Wyłączenie Odpowiedzialności. Stworzyłem tę stronę, aby pomagać czytelnikom osiągnąć sukcesy w
                życiu zawodowym i prywatnym. Mimo, że prezentuję sprawdzone rozwiązania, to nie są one jednakowo
                skuteczne dla wszystkich. Nie powinno się bezwarunkowo korzystać z wszystkiego, co daję. Porady,
                które udostępniam powinny być szczegółowo analizowane, porównywane z własnym przypadkiem,
                ewentualnie konfrontowane z innymi podobnymi publikacjami i dopiero, gdy będą one przedstawiały się
                jako odpowiednie w danym przypadku, wprowadzane w życie.</p>
            <p> 10. Właściciel strony www.wojciechbaryga.pl nie bierze na siebie odpowiedzialności za zamieszczone
                reklamy. Kupujący powinien być ostrożny odpowiadając na reklamę, bądź wysyłając pieniądze.
                Przykładam dużą wagę do tego, aby reklamodawcy, którzy będą tu publikować swoje reklamy byli
                wiarygodni, pomimo to nie mogę odpowiadać za ich czyny.</p>
            <p> 11. Dane adresowe jak i szczegóły oferty każdego reklamodawcy można uznać za pewne tylko w momencie
                publikacji.</p>

            <p> Z poszanowaniem:</p>


            <p> Wojciech Baryga </p>
        </Jumbotron>
    </Layout>
}

export default Privacy;